input#s1,
input#s2,
input#s3,
input#s4,
input#s5 {
  display: none;
}

#slider {
  position: relative;
  width: 50vw;
  height: 20vw;
  /* max-width: 480px;
  max-height: 480px; */
  margin: 10vh auto;
  perspective: 1400px;
  transform-style: preserve-3d;
}

@media (max-width: 8600px) {
  #slider {
    max-width: 28vw;
    max-height: 28vw;
  }
}

@media (max-width: 1600px) {
  #slider {
    max-width: 440px;
    max-height: 440px;
  }
}

@media (max-width: 800px) {
  #slider {
    position: relative;
    margin-top: 15vh;
    height: 40vw;
  }
  #slider img {
    width: 40vw !important;
    height: auto;
  }
}
@media (width: 1024px) {
  #slider {
    position: relative;
    margin-top: 15vh;
    height: 35vw;
  }
  #slider img {
    width: 30vw !important;
    height: auto;
  }
}

input[type="radio"] {
  position: relative;
  top: 108%;
  left: 0%;
  width: 2.5vh;
  height: 2.5vh;
  margin: 0 1vw 0 0;
  opacity: 0.4;
  /* transform: translateX(-83px); */
  cursor: pointer;
}

input[type="radio"]:nth-child(5) {
  margin-right: 0px;
}

input[type="radio"]:checked {
  opacity: 1;
}

#slider label {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  color: white;
  font-size: 70px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 400ms ease;
}

#slider img {
  width: 20vw;
  height: auto;
}

#slide1 {
  background: transparent;
}

#slide2 {
  background: transparent;
}

#slide3 {
  background: transparent;
}

#slide4 {
  background: transparent;
}

#slide5 {
  background: transparent;
}

/* Slider Functionality */

/* Active Slide */
#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
  transform: translate3d(0%, 0, 0px);
}

/* Next Slide */
#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
  transform: translate3d(40%, 0, -100px);
}

/* Next to Next Slide */
#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
  transform: translate3d(80%, 0, -250px);
}

/* Previous to Previous Slide */
#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
  transform: translate3d(-80%, 0, -250px);
}

/* Previous Slide */
#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
  transform: translate3d(-40%, 0, -100px);
}

@media (max-width: 1100px) {
  /* Active Slide */
  #s1:checked ~ #slide1,
  #s2:checked ~ #slide2,
  #s3:checked ~ #slide3,
  #s4:checked ~ #slide4,
  #s5:checked ~ #slide5 {
    transform: translate3d(0%, 0, 0px);
  }

  /* Next Slide */
  #s1:checked ~ #slide2,
  #s2:checked ~ #slide3,
  #s3:checked ~ #slide4,
  #s4:checked ~ #slide5,
  #s5:checked ~ #slide1 {
    transform: translate3d(20%, 0, -100px);
  }

  /* Next to Next Slide */
  #s1:checked ~ #slide3,
  #s2:checked ~ #slide4,
  #s3:checked ~ #slide5,
  #s4:checked ~ #slide1,
  #s5:checked ~ #slide2 {
    transform: translate3d(40%, 0, -250px);
  }

  /* Previous to Previous Slide */
  #s1:checked ~ #slide4,
  #s2:checked ~ #slide5,
  #s3:checked ~ #slide1,
  #s4:checked ~ #slide2,
  #s5:checked ~ #slide3 {
    transform: translate3d(-40%, 0, -250px);
  }

  /* Previous Slide */
  #s1:checked ~ #slide5,
  #s2:checked ~ #slide1,
  #s3:checked ~ #slide2,
  #s4:checked ~ #slide3,
  #s5:checked ~ #slide4 {
    transform: translate3d(-20%, 0, -100px);
  }
}
