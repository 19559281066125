@font-face {
  font-family: altHead;
  src: url(./BrighlyCrushShine-8Ma90.otf);
}
@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

body {
  padding: 0 !important;
  margin: 0 !important;
  background-color: rgb(253, 237, 222);
  caret-color: transparent;
  color: #000;
  font-family: "Bubblegum Sans", cursive;
  letter-spacing: 1px;
}
.b-navbar {
  max-width: 95%;
  margin: 0 auto;
  position: relative;
  left: -60px;
}
.logo-img {
  width: 300px;
  position: relative;
  top: -35px;
}
.nav_link,
.fa-bars {
  color: #000 !important;
}
.nav_link {
  margin-left: 35px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
.nav_link i {
  font-size: 20px;
  margin-top: 7px;
  cursor: pointer;
}
.header-menues {
  position: relative;
  top: -35px;
}
button#dropdown-basic {
  background: black;
  border: none !important;
  outline: none;
  margin-left: 35px;
  font-size: 11px;
  margin-top: 1px;
}
button#dropdown-basic:focus {
  outline: none !important;
  box-shadow: none !important;
}
.dropdown-menu {
  background-color: #000;
}
.nav_link:hover {
  color: rgb(255, 72, 0) !important;
  background: none;
}

.d-heading:hover {
  color: rgb(255, 72, 0) !important;
}
.nav_link a {
  text-decoration: none !important;
  color: #000;
}
.nav_link a:hover {
  color: rgb(255, 72, 0) !important;
}
