@font-face {
  font-family: altHead;
  src: url(./BrighlyCrushShine-8Ma90.otf);
}
@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.header-icons {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
.i-logo img {
  width: 300px;
  z-index: 999;
  position: relative;
  top: -75px;
}
.i-logo {
  display: flex;
  justify-content: center;
}
.b_img {
  position: relative;
  top: -160px;
}
.m-85 {
  max-width: 82%;
  margin: 0 auto;
}
.banner-section {
  max-width: 100vw !important;
}
.banner-section .img-fluid {
  width: 100vw !important;
}
.bt-wrapper {
  max-width: 82%;
  margin: 0 auto;
}
.banner-text {
  padding-bottom: 20px;
}

.e-btn {
  width: 250px;
  font-family: "Bubblegum Sans", cursive;
  height: 70px;
  border-radius: 25px;
  background-color: rgb(255, 72, 0);
  border-radius: 8px;
  color: #000;
  border: 0;
  font-weight: 700;
  font-size: 22px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.e-btn:hover {
  background-color: rgb(255, 72, 0);
  color: #fff;
}
.wrapper-container {
  display: flex;
  margin-top: 30px;
}
.bl-text {
  margin-top: 22px;
  margin-left: 25px;
  font-family: altHead;
}
.b-hr hr {
  width: 250px;
}
.blinker-wrapper h3 {
  font-family: altHead;
}
.s-text {
  font-family: "Bubblegum Sans", cursive;
  margin-top: 50px;
  font-size: 22px;
}
span.blink {
  font-family: "Bubblegum Sans", cursive;
}
