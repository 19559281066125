@font-face {
  font-family: altHead;
  src: url(./BrighlyCrushShine-8Ma90.otf);
}
@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.hero-image {
  max-width: 100vw;
  margin: 0 auto;
  position: relative;
  top: -75px;
}
.hero-image img {
  width: 100vw;
}
.carousel_container {
  margin-left: 0vw;
}
.d_1 h1 {
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 600;
  font-family: altHead;
}
.d_1 p {
  font-size: 18px;
}
.detail-section {
  max-width: 82%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.d_1 {
  max-width: 55%;
}
/* .fa-discord:before,
.fa-twitter:before {
  color: #000;
}
.fa-discord:before:hover,
.fa-twitter:hover {
  color: rgb(255, 72, 0);
} */
.d_1 h1 {
  color: rgb(255, 72, 0);
  font-weight: 600;
  margin-bottom: 30px;
}
.d_2 {
  max-width: 35%;
}
.img_wrapper img {
  width: 50vw;
  height: auto;
}
.img_wrapper2 img {
  width: 30vw;
  height: auto;
}
.m_85 {
  max-width: 82%;
  margin: 0 auto;
}
.dist_heading h1 {
  color: rgb(255, 72, 0) !important;
  font-size: 50px;
  font-family: altHead;
  font-weight: 600;
  margin-top: 40px;
  text-align: center;
}
.dist_heading h3 {
  text-align: center;
  font-size: 18px;
}
.m_p {
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
}
.note-p {
  font-size: 13px;
  width: 350px;
}
.note-detail {
  max-width: 60%;
}
.y-sec-wrapper {
  max-width: 82%;
  margin: 0 auto;
  background-color: rgb(255, 72, 0);
  padding: 22px;
}
.y-sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.y-text {
  color: black;
  font-size: 18px;
  width: 45%;
}
.y-1 {
  font-size: 50px;
  font-weight: bolder;
  color: #000;
}
.y-3 {
  height: 60%;
  border: 1px solid black;
  border-radius: 15px;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: rgb(255, 72, 0);
  font-size: 22px;
  font-weight: 900;
  cursor: pointer;
}
.y-3:hover {
  background: #fff;
  color: #000;
  border: none;
}
.m_85 hr {
  margin-top: 70px;
}
.spec-h {
}
.spec-p {
  font-size: 22px;
}
.spec-detail {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.c-detail p {
  max-width: 75%;
}
.c-detail h1 {
}
.images_section {
  display: flex;
  justify-content: space-between;
}
.ml-80 {
  margin-left: 80px;
  margin-top: 16px;
  font-size: 18px;
}
li {
  font-size: 18px;
}
.text-center {
}
.r-color {
  color: rgb(255, 72, 0);
  font-size: 35px;
  font-weight: 600;
}
.roadmap-points {
  display: flex;
}
.roadmap-section {
  margin-top: 40px;
}
.roadmap h1 {
  font-weight: 600;
  font-family: altHead;
  color: rgb(255, 72, 0);
}
.roadmap p {
  font-size: 18px;
}
.sm-10 {
  margin-left: 90px;
}
.communtiy-tools {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.ct h1 {
}
.ct p {
}
.c-btn {
  height: 60px;
  width: 275px;
  border: 1px solid black;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 72, 0);
  color: #000;
  font-size: 22px;
  font-weight: 900;
  cursor: pointer;
  margin-top: 15px;
}
.c-btn:hover {
  background: #fff;
  color: #000;
  border: none;
}
.m-80 {
  margin-top: 80px;
}
.smart-contract {
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  margin-top: 80px;
}
.veri-text {
  margin-top: 3px;
}
.s-num {
  color: rgb(255, 72, 0);
  text-decoration: underline;
  font-size: 22px;
  cursor: pointer;
  margin-left: 10px;
}
.s-num:hover {
  color: #fff;
}
.l-300 {
  width: 300px;
}
input {
  background-color: #272626;
  border: 0ch;
  height: 40px;
  color: #fff;
  text-indent: 5px;
  caret-color: #fff;
}
.input-email {
  width: 300px;
  display: flex;
}
input:focus {
  outline: none;
  border: none;
}
input::placeholder {
  color: #fff !important;
  font-size: 12px;
}
.fa-long-arrow-right {
  color: #fff !important;
}
.email-submit {
  background-color: #4b4a4a;
  border: 0ch;
  height: 40px;
  color: #fff;
  caret-color: transparent;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  cursor: pointer;
}
.email-submit:hover {
  background-color: rgb(255, 72, 0);
  color: #000;
}
.f-list span {
  padding-right: 25px;
}
.copy-right {
  font-size: 15px;
  margin-top: 15px;
}
.terms {
  color: rgb(255, 72, 0);
  font-size: 15px;
  text-decoration: underline;
  margin-top: 15px;
  cursor: pointer;
}
.email-section p {
}
.footer-section {
  max-width: 82%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.email-section,
.f-social-list {
  margin-top: 40px;
}
.f-social-list {
  margin-top: 55px;
}
.f-line {
  height: 20px;
  background-color: rgb(255, 72, 0);
  margin-top: 25px;
}
.a a {
  text-decoration: none;
  color: #fff;
}
.a i {
  color: #000;
}
.a a:hover {
  color: rgb(255, 72, 0);
}
ul {
  list-style: none;
  margin-top: 30px;
}

ul li::before {
  content: "\2022";
  color: rgb(255, 72, 0);
  font-weight: bold;
  display: inline-block;
  width: 1.3em;
  margin-left: -1em;
}
li a {
  text-decoration: none;
  color: rgb(255, 72, 0);
  cursor: pointer;
}
li a:hover {
  text-decoration: none;
  color: rgb(63, 0, 0);
  cursor: pointer;
}
span i:hover {
  color: rgb(255, 72, 0);
  cursor: pointer;
}
