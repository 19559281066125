@media (max-width: 1320px) {
  .nav_link {
    margin-left: 15px;
  }
  button#dropdown-basic {
    margin-left: 15px;
  }
  .logo-img {
    width: 200px;
  }
  .b-navbar {
    margin-top: 30px;
  }
}
@media (max-width: 1290px) {
  .y-1 {
    text-align: center;
    margin-top: 25px;
  }
  .y-text {
    text-align: center;
    margin-top: 25px;
    width: 100%;
  }
  .y-3 {
    width: 70%;
    margin: 0 auto;
    margin-top: 25px;
  }
  .y-sec {
    max-width: 75%;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
  }
}
@media (max-width: 992px) {
  .logo-img {
    width: 300px;
    position: relative;
    left: -126px;
  }
  .b-navbar {
    margin-top: 0px;
    left: 10px;
  }
  .fa-bars {
    position: relative;
    top: -30px;
  }
  .img_wrapper img {
    width: 100vw;
    height: auto;
  }
  .img_wrapper2 img {
    width: 100vw;
    height: auto;
  }
  .nav_link i {
    display: none;
  }
  button#dropdown-basic {
    margin-left: 5px;
  }
  .hero-image {
    margin-top: 60px;
  }

  .detail-section {
    max-width: 75%;
    flex-wrap: wrap;
  }
  .d_2 {
    max-width: 100% !important;
  }
  .d_1 {
    max-width: 100% !important;
  }
  .dist_heading {
    text-align: center;
  }
  .m_85 {
    max-width: 75%;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .y-3 {
    width: 100% !important;
    font-size: 18px;
  }
  .y-sec-wrapper {
    max-width: 75%;
  }
  .b-img {
    max-width: 300px !important;
  }
  .images_section {
    justify-content: center;
  }
  .s-num {
    margin-left: 0px !important;
    font-size: 13px !important;
  }
  .footer-section {
    max-width: 60%;
    justify-content: center;
  }
  .f-logo {
    position: relative;
    left: -35px;
  }
  .f-social-list {
    margin-top: 15px;
  }
  .dist_heading h3 {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .d_1 h1 {
    font-size: 22px;
  }
  .hero-image {
    max-width: 100%;
    left: 0px;
    margin-top: 0px;
    top: -35px;
  }
  .detail-section {
    max-width: 88%;
  }
  .y-sec-wrapper {
    max-width: 88%;
  }
  .m_85 {
    max-width: 88%;
  }
  .y-1 {
    font-size: 22px;
  }
  .y-3,
  .y-text {
    font-size: 13px;
  }
  .y-sec {
    max-width: 95%;
  }
  .note-detail {
    max-width: 100%;
  }
  .note-p {
    width: 100%;
  }
  .c-detail p {
    max-width: 100%;
  }
  .ml-80 {
    margin-left: 22px;
    margin-top: 10px;
  }
  .sm-10 {
    margin-left: 30px;
  }
  .smart-contract {
    text-align: left;
  }

  .veri-text {
    font-size: 12px;
  }
  .s-num {
    font-size: 11px !important;
  }
  .m-85 {
    max-width: 90% !important;
  }
  .bl-text {
    display: none;
  }
}

@media (max-width: 430px) {
  .fa-bars {
    position: relative;
    top: -130px;
    left: 280px;
  }
  .header-menues {
    position: relative;
    top: -80px;
    left: -10px;
  }
  .dist_heading h1 {
    font-size: 24px;
  }
}
@media (max-width: 360px) {
  .logo-img {
    width: 250px;
    position: relative;
    left: -105px;
  }
  .fa-bars {
    position: relative;
    top: -130px;
    left: 240px;
  }
}
